function getDefaultState() {
    return {
        booking: {},
        bookings: [],
    }
}

const getters = {
    getBooking(state) {
        return state.booking
    },
    getBookings(state) {
        return state.bookings
    },
}

const actions = {
    fetchBooking({commit}) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/profile/last-booking')
                .then(response => {
                    commit('setBooking', response.data)
                    resolve(response)
                })
        })
    },
    fetchBookings({commit}) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/profile/bookings')
                .then(response => {
                    commit('setBookings', response.data)
                    resolve(response)
                })
        })
    },
    saveTraveler(_, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(process.env.VUE_APP_API_URL + '/travel-box/profile/save-traveler', data)
                .then(response => {
                    resolve(response)
                })
                .catch((error)=>{
                    reject(error)
                })
        })
    },
    saveCustomer(_, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(process.env.VUE_APP_API_URL + '/travel-box/profile/save-customer', data)
                .then(response => {
                    resolve(response)
                })
                .catch((error)=>{
                    reject(error)
                })
        })
    },
    changePassword(_, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(process.env.VUE_APP_API_URL + '/travel-box/profile/change-password', data)
                .then(response => {
                    resolve(response)
                })
                .catch((error)=>{
                    reject(error)
                })
        })
    },
    approve(_, hash) {
        return new Promise((resolve, reject) => {
            window.axios.post(process.env.VUE_APP_API_URL + '/travel-box/profile/approve', {
                accept:true,
                hash,
            })
                .then(response => {
                    resolve(response)
                })
                .catch((error)=>{
                    reject(error)
                })
        })
    },
    decline(_, hash) {
        return new Promise((resolve, reject) => {
            window.axios.post(process.env.VUE_APP_API_URL + '/travel-box/profile/approve', {
                accept:false,
                hash,
            })
                .then(response => {
                    resolve(response)
                })
                .catch((error)=>{
                    reject(error)
                })
        })
    },
}

const mutations = {
    setBooking(state, data) {
        state.booking = data
    },
    setBookings(state, data) {
        state.bookings = data.bookings
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}