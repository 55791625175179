function getDefaultState() {
    return {
        hotels: []
    }
}

const getters = {
    getHotels(state) {
        return state.hotels
    },
}

const actions = {
    checkMinDays(_, data) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/hotels/check-min-days/' + data.date + '/' + data.sku)
                .then(response => {
                    resolve(response)
                })
        })
    },
    fetchHotels({commit}) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/hotels/list')
                .then(response => {
                    commit('setHotels', response.data)
                    resolve(response)
                })
        })
    }
}

const mutations = {
    setHotels(state, data) {
        state.hotels = data.hotels
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}