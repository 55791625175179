import Vue from 'vue'
import VueRouter from 'vue-router'
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate"
import de from 'vee-validate/dist/locale/de.json'
import * as rules from "vee-validate/dist/rules"

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize('de', de);

Vue.use(VueRouter)

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)

const routes = [
    {
        path: '/',
        name: 'Travelers',
        component: () => import('../views/Travelers.vue'),
    },
    {
        path: '/room',
        name: 'Room',
        component: () => import('../views/Room.vue'),
    },
    {
        path: '/finish',
        name: 'Finish',
        component: () => import('../views/Finish.vue'),
    },
    {
        path: '/thanks',
        name: 'Thanks',
        component: () => import('../views/Thanks.vue'),
    },
    {
        path: '/profile',
        name: 'Login',
        component: () => import('../views/profile/Login'),
    },
    {
        path: '/profile/booking',
        name: 'Aktuelle Buchung',
        component: () => import('../views/profile/ActualBooking'),
    },
    {
        path: '/profile/my-data',
        name: 'Meine Daten',
        component: () => import('../views/profile/MyData'),
    },
    {
        path: '/profile/bookings',
        name: 'Meine Buchungen',
        component: () => import('../views/profile/Bookings'),
    },
    {
        path: '/approve/:hash',
        name: 'Approve',
        component: () => import('../views/Approve'),
    },
    {
        path: '/decline/:hash',
        name: 'Decline',
        component: () => import('../views/Decline'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
