import Vue from 'vue'
import Vuex from 'vuex'

import Hotels from './modules/hotels'
import Options from './modules/options'
import Travelbox from './modules/travelbox'
import Profile from './modules/profile'

Vue.use(Vuex)

const state = {}

const mutations = {
    set(state, [variable, value]) {
        state[variable] = value
    }
}

export default new Vuex.Store({
    state: state,
    mutations: mutations,
    actions: {},
    modules: {
        Hotels,
        Travelbox,
        Options,
        Profile,
    }
})