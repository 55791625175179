import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store/index'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import FlashMessage from '@smartweb/vue-flash-message'
import {extend} from 'vee-validate'
import moment from 'moment'
import Confirm from '@/components/Confirm'

import "./filters"

const min_date = (value, data) => {
    const min_date = moment(data[0], 'YYYY-MM-DD')

    return moment(value, 'DD.MM.YYYY').isSameOrAfter(min_date)
}

const min_working_days = (value, data) => {
    let lastDay = moment(value[1]);
    let firstDay = moment(value[0]);
    let calcBusinessDays = 1 + (lastDay.diff(firstDay, 'days') * 5 - (firstDay.day() - lastDay.day()) * 2) / 7;

    if (lastDay.day() == 6) calcBusinessDays--;//SAT
    if (firstDay.day() == 0) calcBusinessDays--;//SUN

    return calcBusinessDays >= data[0]
}

extend('min_date', min_date);
extend('min_working_days', min_working_days);

Vue.config.productionTip = false

window.axios = axios
window.axios.defaults.headers = {
    Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
    UserAuthToken: `${process.env.VUE_APP_API_USER_TOKEN}`
}
window.axios.defaults.headers.AuthToken = localStorage.getItem('token') ?? ''

window.axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status == 401) {
        window.location = '/profile'
    } else {
        console.log(error)
        return Promise.reject(error)
    }
});

Vue.use(VueAxios, window.axios)
Vue.use(FlashMessage)

Vue.component('flashMessage', FlashMessage)
Vue.component('confirm', Confirm)

Vue.filter("formatDate", (date, format) => {
    return moment(date).format(format);
})

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
