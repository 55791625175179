function getDefaultState() {
    return {
        data: {},
        rooms: [],
        transfers: [],
        insurances: [],
        food: [],
        cure_packages: [],
        wellness_packages: [],
    }
}

const getters = {
    getPersons(state) {
        return state.persons
    },
    getData(state) {
        return state.data
    },
    getRooms(state) {
        return state.rooms
    },
    getFood(state) {
        return state.food
    },
    getCurePackages(state) {
        return state.cure_packages
    },
    getWellnessPackages(state) {
        return state.wellness_packages
    },
    getTransfers(state) {
        return state.transfers
    },
    getInsurances(state) {
        return state.insurances.filter(insurance => !['INSR_OSB', 'INSR_CRN'].includes(insurance.sku))
    },
    getCrnInsurance(state) {
        return state.insurances.find(insurance => insurance.sku == 'INSR_CRN') || null
    },
    getOsbInsurance(state) {
        return state.insurances.find(insurance => insurance.sku == 'INSR_OSB') || null
    },
}

const actions = {
    fetchData({commit}, hash) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/get/' + hash)
                .then(response => {
                    commit('setData', response.data)
                    resolve(response)
                })
        })
    },

    fetchRooms({commit}, sku) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/rooms/' + sku)
                .then(response => {
                    commit('setRooms', response.data)
                    resolve(response)
                })
        })
    },
    fetchTransfers({commit}, sku) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/transfers/' + sku)
                .then(response => {
                    commit('setTransfers', response.data)
                    resolve(response)
                })
        })
    },
    fetchInsurances({commit}) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/insurances/')
                .then(response => {
                    commit('setInsurances', response.data)
                    resolve(response)
                })
        })
    },
    fetchFood({commit}, sku) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/food/' + sku)
                .then(response => {
                    commit('setFood', response.data)
                    resolve(response)
                })
        })
    },
    fetchCurePackages({commit}, sku) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/cure-packages/' + sku)
                .then(response => {
                    commit('setCurePackages', response.data)
                    resolve(response)
                })
        })
    },
    fetchWellnessPackages({commit}, sku) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/wellness-packages/' + sku)
                .then(response => {
                    commit('setWellnessPackages', response.data)
                    resolve(response)
                })
        })
    },
    save(_, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(process.env.VUE_APP_API_URL + '/travel-box/save', data)
                .then(response => {
                    resolve(response)
                })
                .catch((error)=>{
                    reject(error)
                })
        })
    },
}

const mutations = {
    setData(state, data) {
        state.data = data
    },
    setRooms(state, data) {
        state.rooms = data.rooms
    },
    setTransfers(state, data) {
        state.transfers = data.transfers
    },
    setInsurances(state, data) {
        state.insurances = data.insurances
    },
    setFood(state, data) {
        state.food = data.food
    },
    setCurePackages(state, data) {
        state.cure_packages = data.cure_packages
    },
    setWellnessPackages(state, data) {
        state.wellness_packages = data.wellness_packages
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}