<template>
    <v-app>
        <v-overlay :value="loading">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-main class="light-blue">
            <router-view/>
        </v-main>

        <FlashMessage time="20000" style="z-index: 5"/>
        <confirm ref="confirm"></confirm>
    </v-app>
</template>

<script>
    export default {
        name: 'App',

        data: () => ({
            loading: true
        }),
        methods: {
            showLoading() {
                this.loading = true
            },
            hideLoading() {
                this.loading = false
            },
            showMessage(message, status = 'success') {
                this.flashMessage.show({
                    status: status,
                    message: message
                })
            },
        }
    };
</script>
