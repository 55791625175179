function getDefaultState() {
    return {
        options: {
            prefixes: [],
            genders: [],
            titles: [],
            statuses: [],
        },
        configuration: {}
    }
}

const getters = {
    getPrefixes(state) {
        return state.options.prefixes
    },
    getGenders(state) {
        return state.options.genders
    },
    getTitles(state) {
        return state.options.titles
    },
    getStatuses(state) {
        return state.options.statuses
    },
    getConfiguration(state) {
        return state.configuration
    },
}

const actions = {
    fetchOptions({commit}) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/cotravelers/options')
                .then(response => {
                    commit('setOptions', response.data)
                    resolve()
                })
        })
    },
    fetchConfiguration({commit}) {
        return new Promise((resolve) => {
            window.axios.get(process.env.VUE_APP_API_URL + '/travel-box/configuration')
                .then(response => {
                    commit('setConfiguration', response.data)
                    resolve()
                })
        })
    },
}

const mutations = {
    setOptions(state, options) {
        state.options = options
    },
    setConfiguration(state, configuration) {
        state.configuration = configuration
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}