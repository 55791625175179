import Vue from "vue"

Vue.filter("optionsVV", (data) => {
    let options = []

    data.forEach(val => {
        options.push({value: val, text: val})
    })

    return options
})

Vue.filter("optionsVL", (data) => {
    let options = []

    Object.keys(data).forEach(val => {
        options.push({value: val, text: data[val]})
    })

    return options
})